/* Time (Current Time and Duration) */
.rhap_time {
    color: #fff; /* Set text color */
    font-size: 14px; /* Adjust font size */
  }

  .rhap_progress-buffer {
    background-color: #00ff00; /* Change this to your desired preloaded color */
  }
  
  /* Play/Pause Button */
  .rhap_main-controls-button {
    color: #fff; /* Change button color */
    font-size: 35px; /* Adjust button size */
  }
  
  /* Forward and Rewind Buttons */
  .rhap_additional-controls-button {
    color: #fff;
    font-size: 30px; 
  }
  
  /* Progress Bar */
  .rhap_progress-indicator {
    background-color: rgb(194, 190, 190); /* Change progress indicator color */
  }
  
  .rhap_progress-filled {
    background-color: rgb(171, 167, 167); /* Change filled progress bar color */
  }
  
  /* Player Background */
  .rhap_container {
    background-color: #2C0E5E !important; /* Player background color */
    border-radius: 8px;
    color: #fff;
  }
  